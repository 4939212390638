@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Noto+Sans:ital,wght@0,400;0,700;1,400");

body {
  font-family: "Noto Sans", sans-serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.app {
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.5);
}
