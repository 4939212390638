@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css");
/*Font Awesome CDN */

.community {
  padding-top: 1rem;
  padding-left: 1.5rem;
  padding-bottom: 0.5rem;
  line-height: 1.5em;
  color: rgba(0, 0, 0, 1);
  font-size: 20px;
  text-align: left;
}
.community h2 {
  font-size: 34px;
  font-family: "Bebas Neue", sans-serif;
}
.community__signature {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 2.5rem;
  text-align: left;
}
.community__links {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 3rem;
}
.community__links a {
  padding: 0.5rem;
}
.image-box {
  box-shadow: 2px 2px 2px rgba(40, 44, 52, 0.4);
  color: rgba(255, 255, 255, 1);
  border-radius: 5%;
  text-align: center;
  vertical-align: middle;
  padding: 15px;
}
.twitter-color {
  background-color: rgba(29, 161, 242, 1);
}
.reddit-color {
  background-color: rgba(255, 69, 0, 1);
}
.telegram-color {
  background-color: rgba(0, 136, 204, 1);
}

@media (max-width: 700px) {
  .community {
    font-size: 18px;
    width: 85vw;
  }
  .community h2 {
    font-size: 30px;
  }
}
