.banner__hero {
  overflow: hidden;
}
.banner__hero-row {
  display: flex !important;
  vertical-align: bottom !important;
  align-items: flex-end !important;
  align-items: center !important;

  width: 100vw;
  margin-top: 70px;
  padding-top: 30px;
  background: rgb(1, 101, 116);
  background: url(./../assets/banner-moon.png) no-repeat 110% 180%,
    url(./../assets/banner-stars.png),
    linear-gradient(
      90deg,
      rgba(1, 101, 116, 1) 0%,
      rgba(22, 28, 79, 1) 48%,
      rgba(17, 45, 81, 100) 100%
    );
}
.banner__hero-image {
  position: relative;
  top: 0.5rem;
}
.banner__hero-row h1 {
  font-size: 36px;
  line-height: 37px;
  color: rgba(255, 255, 255, 1);
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 1);
  padding-right: 100px;
}
.banner__hero-row h2 {
  font-size: 26px;
  line-height: 27px;
  color: rgba(255, 255, 255, 1);
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 1);
  padding-right: 100px;
  padding-top: 50px;
}

@media (max-width: 1000px) {
  .banner__hero-row h1 {
    font-size: 30px;
    line-height: 31px;
    padding-left: 5px;
    padding-right: 60px;
  }
  .banner__hero-row h2 {
    font-size: 22px;
    line-height: 23px;
    padding-left: 10px;
    padding-right: 60px;
    padding-top: 5px;
  }
}

@media (max-width: 700px) {
  .banner__hero-row {
    position: relative;
    height: 450px;
    flex-direction: column-reverse;
  }
  .banner__hero-row h1 {
    font-size: 22px;
    line-height: 23px;
  }
  .banner__hero-row h2 {
    font-size: 18px;
    line-height: 19px;
  }
  .banner__hero-col-1 {
    position: absolute;
    left: 20px;
  }
  .banner__hero-col-2 {
    position: absolute;
    top: 0.5rem;
    left: 10px;
  }
}
