@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css");
/*Font Awesome CDN */

.tokenomics {
  padding-left: 1.5rem;
  line-height: 1.5em;
}
.tokenomics h2 {
  font-size: 34px;
  font-family: "Bebas Neue", sans-serif;
}
.tokenomics__card-group {
  display: grid;
  grid-template-columns: repeat(4, 200px);
  text-align: center;
  justify-content: flex-start;
  gap: 2rem;
}
.tokenomics__card {
  box-shadow: 0 4px 8px 0 rgba(40, 44, 52, 0.2),
    0 6px 20px 0 rgba(40, 44, 52, 0.19);
  color: rgba(255, 255, 255, 1);
  border-radius: 5%;
  padding: 1rem;
}
.tokenomics__card-image {
  height: 4rem;
  width: auto;
}
.tokenomics__card-image:before {
  background-color: rgba(247, 146, 86, 1);
}
.tokenomics__card-title {
  width: 11rem;
  font-size: 19px;
  font-weight: bold;
  padding-top: 0.5rem;
  color: rgba(0, 0, 0, 1);
}
.tokenomics a {
  text-decoration: none; /* no underline */
  color: rgb(0, 178, 202, 1);
  padding-bottom: 1px;
  font-size: 20px;
}
.tokenomics a:hover {
  color: rgba(247, 146, 86, 1);
}

@media (max-width: 1000px) {
  .tokenomics__card-group {
    grid-template-columns: repeat(3, 200px);
  }
}

@media (max-width: 700px) {
  .tokenomics h2 {
    font-size: 30px;
  }
  .tokenomics__card-group {
    grid-template-columns: repeat(2, 160px);
    gap: 1rem;
  }
  .tokenomics__card-image {
    height: 3rem;
  }
  .tokenomics__card-title {
    width: 8rem;
    font-size: 15px;
  }
  .tokenomics a {
    font-size: 16px;
  }
}