@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css");
/*Font Awesome CDN */

.about {
  padding-left: 1.5rem;
  padding-bottom: 0.5rem;
  line-height: 1.5em;
  color: rgba(0, 0, 0, 1);
  font-size: 20px;
  text-align: left;
}
.about h2 {
  font-size: 34px;
  font-family: "Bebas Neue", sans-serif;
}
.about h3 {
  font-size: 22px;
}
.about a {
  text-decoration: none; /* no underline */
  color: rgb(0, 178, 202, 1);
  padding-bottom: 1px;
  font-size: 20px;
  word-break: break-all;
}
.about img {
  padding-top: 1rem;
}
.about a:hover {
  color: rgba(247, 146, 86, 1);
}
.about__coin-stack {
  display: flex !important;
  vertical-align: top !important;
  align-items: flex-end !important;
  align-items: center !important;
  overflow: hidden;

  width: 100vw;
  padding-top: 0.5rem;
  background: rgb(255, 255, 255, 1);
}
.about__coin-stack-col-1 {
  z-index: 399; /* Keep it in front */
  width: 75vw;
}
.about__coin-stack-col-2 {
  right: 0;
  width: 20vw;
}

@media (max-width: 700px) {
  .about {
    font-size: 18px;
  }
  .about h2 {
    font-size: 30px;
  }
  .about h3 {
    font-size: 20px;
  }
  .about__coin-stack-col-1 {
    font-size: 18px;
    width: 90vw;
  }
  .about__coin-stack-col-2 {
    width: 0vw; /* hide image */
  }
}
