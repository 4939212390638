@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css");
/*Font Awesome CDN */

.header {
  position: fixed;
  top: 0; /* Stick it to the top */
  left: 0; /* Stick it to the left */
  z-index: 999; /* Keep it in front */
  max-height: 70px;
  width: 100vw;

  display: grid;
  grid-template-areas: "logo get nav";

  /* Cosmetics */
  background-color: rgba(40, 44, 52, 1);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding-top: 5px;
  padding-bottom: 5px;
}
.header__logo {
  grid-area: logo;
  height: 70px;
}
.header__get-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.header__get {
  grid-area: get;
  padding: 8px;
  outline: none;
  border: none;
  font-size: 28px;
  color: rgba(255, 255, 255, 1);
  font-weight: 600;
  font-family: "Bebas Neue", sans-serif;
  background-color: rgba(255, 0, 122, 0.8);
  border-radius: 10px;
  cursor: pointer;
  transition: 70ms;
}
.header__get:hover {
  transform: scale(1.1);
  color: rgba(30, 30, 36);
  background-color: rgba(247, 146, 86, 0.9);
}
.header__nav {
  display: grid;
  grid-area: nav;
  grid-template-columns: repeat(4, auto);
  align-items: center;
  justify-items: center;
}
.header__nav a {
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  font-weight: 500;
  transition: 0.5s;
  text-decoration: none;
}
.header__nav a:hover {
  transform: scale(1.15);
  color: rgba(247, 146, 86, 0.9);
}
.header__menu {
  display: none;
  grid-area: menu;
  margin: 0 10px 0 0;
  padding: 0;
  justify-self: end;
  border: none;
  background: none;
  outline: none;
  transition: 0.2s;
}
.header__menu:active {
  color: scale(1.1);
}
.header__menu i {
  color: rgba(255, 255, 255, 1);
}

@media (prefers-reduced-motion: no-preference) {
  .header__logo {
    animation: header__logo-spin infinite 15s linear;
  }
}

@media (max-width: 700px) {
  .header {
    grid-template-areas: "logo get menu" "nav nav nav";
  }
  .header__nav {
    grid-template-rows: repeat(4, auto);
    grid-template-columns: none;
    grid-row-gap: 20px;

    padding: 30px 0 30px;
    background: rgba(40, 44, 47, 0.95);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .header__menu {
    display: inline;
  }
}

@keyframes header__logo-spin {
  from {
    transform: rotate(-20deg);
  }
  to {
    transform: rotate(20deg);
  }
}

.header__nav-animation-enter {
  opacity: 0;
  transform: scale(0.5);
}
.header__nav-animation-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 350ms, transform 350ms;
}
.header__nav-animation-exit {
  opacity: 1;
}
.header__nav-animation-exit-active {
  opacity: 0;
  transform: scale(0.5);
  transition: opacity 350ms, transform 350ms;
}
